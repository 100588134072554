@import url('https://fonts.cdnfonts.com/css/inter');

@font-face {
  font-family: 'Graphik LC';
  src: url('/src/assets/fonts/Graphik LC-Regular.ttf') format('truetype'),
       url('/src/assets/fonts/._Graphik LC-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Graphik LC';
  src: url('/src/assets/fonts/Graphik LC-Medium.ttf') format('truetype'),
       url('/src/assets/fonts/._Graphik LC-Medium.ttf') format('truetype');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'Graphik LC';
  src: url('/src/assets/fonts/Graphik LC-Semibold.ttf') format('truetype'),
       url('/src/assets/fonts/._Graphik LC-Semibold.ttf') format('truetype');
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'Graphik LC';
  src: url('/src/assets/fonts/Graphik LC-Bold.ttf') format('truetype'),
       url('/src/assets/fonts/._Graphik LC-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'SangBleu Sunrise';
  src: url('/src/assets/fonts/._SangBleuSunrise-BoldItalic-WebS.eot') format('embedded-opentype');
  src: url('/src/assets/fonts/SangBleuSunrise-BoldItalic.ttf') format('truetype'),
       url('/src/assets/fonts/._SangBleuSunrise-BoldItalic.otf') format('opentype'),
       url('/src/assets/fonts/._SangBleuSunrise-BoldItalic.ttf') format('truetype'),
       url('/src/assets/fonts/._SangBleuSunrise-BoldItalic-WebS.ttf') format('truetype'),
       url('/src/assets/fonts/._SangBleuSunrise-BoldItalic-WebS.eot') format('embedded-opentype'),
       url('/src/assets/fonts/._SangBleuSunrise-BoldItalic-WebS.woff') format('woff'),
       url('/src/assets/fonts/._SangBleuSunrise-BoldItalic-WebS.woff2') format('woff2'),
       url('/src/assets/fonts/._SangBleuSunrise-BoldItalic-WebXL.ttf') format('truetype'),
       url('/src/assets/fonts/._SangBleuSunrise-BoldItalic-WebXL.eot') format('embedded-opentype'),
       url('/src/assets/fonts/._SangBleuSunrise-BoldItalic-WebXL.woff') format('woff'),
       url('/src/assets/fonts/._SangBleuSunrise-BoldItalic-WebXL.woff2') format('woff2'),
       url('/src/assets/fonts/._SangBleuSunrise-BoldItalic-WebS.svg') format('svg'),
       url('/src/assets/fonts/._SangBleuSunrise-BoldItalic-WebXL.svg') format('svg');
  font-style: italic;
  font-weight: 700;
}
