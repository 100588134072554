@import './_fonts.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  /*font-family: 'Graphik LC', sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(26, 26, 26, 1);
  text-underline-offset: 3px;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

video {
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}

.myTransition {
  transition: ease-in-out;
  transition-property: opacity, visibility;
  transition-duration: 0.7s, 0s;
  transition-delay: 0s, 0.7s;
}

.mainButtonTransition {
  transition: ease-in-out;
  transition-property: background-image;
  transition-duration: 0.3s, 0s;
}

.tgButtonTransition {
  transition: all 0.3s ease-in-out;
}

.navMenuShadow {
  border: 1px solid rgba(26, 26, 26, 0.05);
  box-shadow: 0 8px 60px rgba(26, 26, 26, 0.2);
}

.custom-outline:focus {
  outline: none;
}

.typingText {
  /*font-family: 'SangBleu Sunrise', serif;*/
  font-style: italic;
  /*font-weight: 700;*/
}

.typingCursor {
  animation: blink .75s step-end infinite;
}

.gradientButton {
  background-color: #1A1A1A;
}

.gradientButton.animate-start {
  animation: gradient 3s linear normal;
}

.gradientButton.animate-end {
  animation: gradient 3s linear reverse;
}

@keyframes gradient {
  0% {
    background-image: url("../assets/images/Button0.png");
  }
  20% {
    background-image: url("../assets/images/Button1.png");
  }
  40% {
    background-image: url("../assets/images/Button2.png");
  }
  60% {
    background-image: url("../assets/images/Button3.png");
  }
  80% {
    background-image: url("../assets/images/Button4.png");
  }
  100% {
    background-image: url("../assets/images/Button5.png");
  }
}

.footerGradient {
  position: absolute;
  left: 50%;
  right: 50%;
  bottom: 0;
  transform: translateX(-50%);

  width: 100%;
  min-width: 1698px;
}

@media (max-width: 639px) {
  .footerGradient {
    height: 400px;
    min-width: 1023px;
  }
}

.benefit {
  transition: opacity 0.3s ease-in-out;
}

@keyframes blink {
	from, to { 
		background-color: transparent 
	}
	50% { 
		background-color: #000; 
	}
}

.animated-text.visible {
  background: linear-gradient(to right, #D252FF 50%, white 50%);
  background-size: 200% 100%;
  background-position: 100% 0;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: fillText 3s linear;
  animation-fill-mode: forwards;
}

@keyframes fillText {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.features {
  opacity: 0;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.features.visible {
  opacity: 1;
}

.features::-webkit-scrollbar {
  display: none;
}

.slideInUp {
  display: inline-block;
  opacity: 0;
  transform: translateY(60%);
}

.features.visible .slideInUp {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.5s, transform 0.5s;
}

.clubGradient {
  background: linear-gradient(270deg, #290DD2 0%, #6A4DFF 31.46%, #FA84F6 65.88%, #FDFD00 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

#particles-js {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 40%;
  z-index: 20px;
}